module controllers {
    export module master {
        interface IProductSKUPackagingUpdateScope extends ng.IScope {
        }

        interface IProductSKUPackagingUpdateScopeParams extends ng.ui.IStateParamsService {
            skuId: number;
        }

        export class productSKUPackagingUpdateCtrl {

            static $inject = [
                "$scope",
                '$rootScope',
                'generalService',
                '$q',
                '$anchorScroll',
                '$uibModal',
                'bsLoadingOverlayService',
                '$stateParams',
                'entityService',
                '$state',
                '$timeout',
                '$transitions',
                'documentRepositoryService',
                'skuService',
                'classificationValueService',
                'typeOfPackageService',
            ];


            skuId: number;
            productSKUPackagingMaster: interfaces.master.IProductSKUPackagingMaster;

            breadCrumbDesc: string;

            isLoadingDetail: boolean = false;

            constructor(
                private $scope: IProductSKUPackagingUpdateScope,
                private $rootScope: interfaces.applicationcore.IRootScope,
                private generalService: interfaces.applicationcore.IGeneralService,
                private $q: ng.IQService,
                private $anchorScroll: ng.IAnchorScrollService,
                private $uibModal: ng.ui.bootstrap.IModalService,
                private bsLoadingOverlayService,
                private $stateParams: IProductSKUPackagingUpdateScopeParams,
                private entityService: interfaces.applicationcore.IEntityService,
                private $state: ng.ui.IStateService,
                private $timeout: ng.ITimeoutService,
                public $transitions: ng.ui.core.ITransition,
                public documentRepositoryService: interfaces.documentManagement.IDocumentRepositoryService,
                public skuService: services.master.skuService,
                private classificationValueService: interfaces.applicationcore.IClassificationValueService,
                public typeOfPackageService: interfaces.master.ITypeOfPackageService,
            ) {

                this.skuId = $stateParams.skuId;

                var loadPromises: ng.IPromise<any>[] = [];

                loadPromises.push(this.loadProductSKUPackaging(this.skuId));

                this.bsLoadingOverlayService.wrap({
                    referenceId: 'prodSkuPack.update'
                },
                    () => {
                        return $q.all(loadPromises);
                    });
            }

            loadProductSKUPackaging(Id: number) {
                return this.skuService.getProductSKUPackaging(Id).query((data: interfaces.master.IProductSKUPackagingMaster) => {

                    this.productSKUPackagingMaster = data;
                    this.breadCrumbDesc = this.productSKUPackagingMaster.Code + " - " + this.productSKUPackagingMaster.Description;

                }
                    , (errorResponse) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                    }).$promise;
            }

            loadUnitOfmeasures() {
                return this.classificationValueService.GetClassificationValueDropdownByClassification().query({
                    classificationId: Enum.ClassificationEnum.VolumeUnitOfMeasure
                }, (resultList) => {
                    }, (errorResponse) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                    }).$promise;
            }

            loadTypeOfPackage(searchText: string) {
                return this.typeOfPackageService.getDropdownList(this.productSKUPackagingMaster.OwnerEntityId).query(() => {
                }, (errorResponse) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                }).$promise;
            }

            SaveProductSKUPackaging(): ng.IPromise<boolean> {
                var deferre = this.$q.defer<boolean>();

                this.skuService.save().save(this.productSKUPackagingMaster, (data: interfaces.applicationcore.IMessageHandler) => {
                    this.generalService.displayMessageHandler(data);

                    this.skuId = Number(data.ID);

                    deferre.resolve(true);
                }, (errorResponse) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                    deferre.resolve(false);
                });

                return deferre.promise;
            };

            Save() {
                this.bsLoadingOverlayService.wrap({
                    referenceId: 'prodSkuPack.update'
                },
                    () => {
                        return this.SaveProductSKUPackaging().then((data: boolean) => {
                            if (data) {
                                this.loadProductSKUPackaging(this.skuId);
                                this.$anchorScroll("topAnchor");
                                this.$state.go("auth.MasterData.Product.ProductSKUPackaging.Update", { skuId: this.skuId });
                            }
                        });
                    });

            }

            SaveClose() {
                this.bsLoadingOverlayService.wrap({
                    referenceId: 'prodSkuPack.update'
                },
                    () => {
                        return this.SaveProductSKUPackaging().then((data) => {
                            if (data) {
                                this.$state.go("^");
                            } else {
                                this.$anchorScroll("topAnchor");
                            }
                        });
                    });
            }

            Close() {
                this.$state.go("^");
            }
        };

        angular.module("app").controller("productSKUPackagingUpdateCtrl", controllers.master.productSKUPackagingUpdateCtrl);
    }
}
